<template>
	<div class="wrap">
		<!-- <van-nav-bar title="米因人工智能客服" fixed :border="false" :z-index="9999" class="qjc-nav-bar" ></van-nav-bar> -->
		<div class="gpt" ref="scrollDiv" :style="{height: listHeight +'rem'}">
			<div class="to_tips">內容由百度文心一言提供</div>
			<div class="chat_box">
				<div class="chat_avatar">
					<van-image width="0.88rem" height="0.88rem" class="avatar_img" :src="require('@/assets/images/touxiang.png')"></van-image>
				</div>
				<div class="chat_content">
					<div class="chat_text">你好，我是人工智能語言模型，我可以回答你的問題，為您提供有用信息。</div>
				</div>
			</div>
			<div class="" v-for="(item, index) in list">
				<div class="chat_box user" v-if="item.type == 'user' && item.fail != 'fail'">
					<div class="chat_content">
						<div class="chat_text">{{item.result}}</div>
					</div>
					<div class="chat_avatar">
						<van-image width="0.88rem" height="0.88rem" class="avatar_img" :src="require('@/assets/images/no-avatar.png')"></van-image>
					</div>
				</div>
				<div class="chat_box" v-if="item.type != 'user' && item.fail != 'fail'">
					<div class="chat_avatar">
						<van-image width="0.88rem" height="0.88rem" class="avatar_img" :src="require('@/assets/images/touxiang.png')"></van-image>
					</div>
					<div class="chat_content">
						<div class="chat_text" v-html="item.result"></div>
					</div>
				</div>
				<div class="fail_cell" v-if="item.fail == 'fail'">
					<div class="to_tips">您撤回了一条消息</div>
					<div class="to_tips ">{{item.msg ? item.msg : '抱歉，因您發送的消息可能含有不良信息，消息發送失敗'}}</div>
				</div>
			</div>
			<div class="chat_box " v-if="isLoading">
				<div class="chat_avatar">
					<van-image width="0.88rem" height="0.88rem" class="avatar_img" :src="require('@/assets/images/touxiang.png')"></van-image>
				</div>
				<div class="chat_content">
					<div class="chat_text">稍等片刻，正在為您解答 <span class="tips1" v-if="isTip1"></span><span class="tips2" v-if="isTip2"></span><span class="tips3" v-if="isTip3"></span> </div>
				</div>
			</div>
		</div>
		<div class="foot_cell">
			<div class="about_popup" v-if="aboutList && aboutList.length > 0" >
				<div class="about_item" v-for="(item, index) in aboutList" :key="index" @click="handleItem(item)">{{item.ask}}</div>
			</div>
			<div class="input_wrap">
				<van-field class="input_cell" v-model="gptval" @input="handleInput"  placeholder="請輸入您想了解的內容" type="text" />
				<van-button class="foot_btn" @click="handleSub">發送</van-button>
			</div>
		</div>
	</div>
	<!-- @focus="handleFocus" @blur="handleBlur" -->
</template>
<script>
import { getGpt2 } from '@/js/axios';
import Vue from 'vue';
import { Toast, NavBar, Image, Field, Button } from 'vant';
	Vue.use(Toast)
	   .use(NavBar)
	   .use(Field)
	   .use(Button)
	   .use(Image);


export default {
	name: 'gpt',
	data() {
		return {
			gptval: '',
			list: [],
			aboutList: [],
			hotList: [],
			listHeight: 0,
			isLoading: false,
			isTip1: false,
			isTip2: false,
			isTip3: false,
			ratio: '',
			originHeight: '',
		};
	},
	created() {
		
	},
	components:{
		
	},
	computed: {
	
	},
	beforeDestroy() {
		// 移除绑定的handleResize事件监听
		window.removeEventListener("resize",this.handleResize);
		console.log('移除resize监听');
	},
	mounted() {
		let clientWidth = document.documentElement.clientWidth;
		let clientHeight = document.documentElement.clientHeight;
		let ratio = clientWidth / 375;
		this.ratio = ratio
		let list_height = clientHeight - (1.2 * 100 * ratio / 2);
		this.listHeight = (list_height/100)/ratio * 2; //历史记录 高度
		
		window.addEventListener('resize',this.handleResize);
	},
	watch: {
		isLoading(newVal, oldVal) {
			if(newVal) {
				this.isTip1 = true
				setTimeout(()=> {
					this.isTip2 = true
				}, 500);
				setTimeout(()=> {
					this.isTip3 = true
				}, 1000);
			}
		}
	},
	methods: {
		handleResize() {
			var resizeHeight = document.documentElement.clientHeight || document.body.clientHeight;
			   if (resizeHeight < this.originHeight) {
			     //当软键盘弹起，在此处操作
					this.handleFocus()
			   } else {
			     //当软键盘收起，在此处操作
					this.handleBlur()
			   }
		},
		handleFocus(e) {
			setTimeout(() => {
				let initHeight = window.innerHeight;
				let list_height = initHeight - (1.2 * 100 * this.ratio / 2);
				this.listHeight = (list_height/100)/this.ratio * 2
				this.handleScrollBottom();
			}, 300)
		},
		handleBlur(e) {
			setTimeout(() => {
				let initHeight = window.innerHeight;
				let list_height = initHeight - (1.2 * 100 * this.ratio / 2);
				this.listHeight = (list_height/100)/this.ratio * 2
				this.handleScrollBottom();
			}, 300)
		},
		handleInput(e) {
			let that = this
			if(e.trim() != '') {
				let data = {
					ask: e,
					act: 'expand'
				}
				let iconRule = /[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF][\u200D|\uFE0F]|[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF]|[0-9|*|#]\uFE0F\u20E3|[0-9|#]\u20E3|[\u203C-\u3299]\uFE0F\u200D|[\u203C-\u3299]\uFE0F|[\u2122-\u2B55]|\u303D|[\A9|\AE]\u3030|\uA9|\uAE|\u3030/ig
				if (iconRule.test(this.gptval)) {
					Toast.fail('不支持发送表情');
					this.gptval = '' 
					return
				}
				getGpt2(data).then((res) => {
					if(res.code == 200) {
						let res1 = JSON.parse(window.atob(res.data))
						console.log(res1)
						that.aboutList = res1
					} else {
						setTimeout(() => {
							that.aboutList = []
						}, 200)
					}
				})
			} else {
				setTimeout(() => {
					that.aboutList = []
				}, 300)
				
			}
		},
		handleItem(item) {
			let list = this.list
			list.push({
				type: 'user',
				result: item.ask
			})
			this.handleScrollBottom();
			list.push({
				result: item.answer.replaceAll('\n', '<br>')
			})
			
			this.handleScrollBottom();
			this.list = list
			this.gptval = ''
			this.aboutList = []
		},
		handleSub(item) {
			if(this.gptval.trim() == '') {
				this.gptval = ''
				Toast('不能發送空白消息');
				return
			}
			let iconRule = /[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF][\u200D|\uFE0F]|[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF]|[0-9|*|#]\uFE0F\u20E3|[0-9|#]\u20E3|[\u203C-\u3299]\uFE0F\u200D|[\u203C-\u3299]\uFE0F|[\u2122-\u2B55]|\u303D|[\A9|\AE]\u3030|\uA9|\uAE|\u3030/ig
			if (iconRule.test(this.gptval)) {
				Toast.fail('不支持发送表情');
				this.gptval = '' 
				return
			}
			let list = this.list;
			
			let val = this.gptval
			if(item != 'no') {
				list.push({
					type: 'user',
					result: this.gptval
				})
			}
			this.isLoading = true
			
			this.list = list
			this.gptval = ''
			this.aboutList = []
			this.handleScrollBottom();
			let data = {
				ask: val,
				act: ''
			}
			getGpt2(data).then((res) => {
				if(res.code == 200) {
					let res1 = JSON.parse(window.atob(res.data))
					list.push({
						result: res1.result.replaceAll('\n', '<br>')
					})
					this.isLoading = false
					this.handleScrollBottom();
					this.list = list
					this.aboutList = []
				} else if(res.code == 201){
					// list.pop()
					list[list.length - 1].fail = 'fail'
					if(res.msg) {
						list[list.length - 1].msg = res.msg
					}
					this.handleScrollBottom();
					this.isLoading = false
					this.list = list
					console.log(list)
					this.aboutList = []
				} else if(res.code == 0){
					Toast.fail(res.msg);
					list.pop()
					this.handleScrollBottom();
					this.isLoading = false
					this.list = list
					this.aboutList = []
				}else {
					Toast.fail(res.msg);
				}
			})
		},
		handleScrollBottom() {
		  this.$nextTick(() => {
			let scrollElem = this.$refs.scrollDiv;
			scrollElem.scrollTop = scrollElem.scrollHeight;
		  });
		}
	}
};
</script>

<style>
	.wrap {
			width: 100%;
			min-height: 100vh;
			/* padding-top: 0.24rem; */
			padding-bottom: 1.2rem;
			background-color: #f3f3f3;
			overflow: auto;
			
		}
		.about_popup {
			position: absolute;
			bottom: 1.12rem;
			left: 0;
			width: 100%;
			padding: 0.3rem;
			border-radius: 0 0.08rem 0.08rem 0;
			background: #fff;
			border-bottom: 0.01rem solid #eee;
		}
		.about_item {
		    width: 100%;
		    padding-top: 0.10rem;
		    padding-bottom: 0.16rem;
		    border-bottom: 0.01rem solid #eee;
			text-align: left;
		}
		.about_item:nth-last-child(1) {
		    border-bottom: none;
		    padding-bottom: 0;
		}
		.qjc-nav-bar {
			border-bottom: none;
		}
		
		.back-icon {
			width: 0.42rem;
			height: 0.42rem;
		}
		.gpt {
			width: 100%;
			padding:0.24rem;
			overflow: auto;
		}
		.chat_box {
			width: 100%;
			display: flex;
			align-items: flex-start;
			margin-bottom: 0.5rem;
		}
		.chat_avatar {
			text-align: left;
		}
		.chat_content {
			flex: 1;
			margin-left: 0.2rem;
			text-align: left;
			display: flex;
		}
		.chat_text {
			padding: 0.24rem;
			background-color: #fff;
			border-radius: 0.12rem;
		}
		.user .chat_content {
			flex: 1;
			margin-right: 0.2rem;
			text-align: left;
			display: flex;
			justify-content: flex-end;
		}
		.user .chat_text {
			background-color: #aae97a;
		}
		.foot_cell {
			width: 100%;
			position: fixed;
			bottom: 0;
			left: 0;
			padding: 0.16rem 0 0.24rem;
			background-color: #f3f3f3;
			border-top: 0.01rem solid #dedede;
		}
		.input_wrap {
			display: flex;
			align-items: center;
			padding: 0 0.24rem;
		}
		.input_cell {
			width: 100%;
			height: 0.64rem;
			display: flex;
			align-items: center;
			background-color: #fff;
			border-radius: 0.4rem;
		}
		.foot_btn {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 1.4rem;
			height: 0.64rem;
			padding: 0;
			font-size: 0.28rem;
			border-radius: 0.2rem;
			margin-left: 0.2rem;
			background-color: #aae97a;
			color: #333;
			border: none;
		}
		.tips1 {
			position: relative;
			display: inline-block;
			width: 0.16rem;
			height: 0.16rem;
			background-image: linear-gradient(90deg, #90EE90,#00FF00);
			border-radius: 0.06rem;
			animation: fly 1.5s linear infinite;
		}
		@keyframes fly {
		  0% {
			top: 0
		  }
		  33% {
		    top: 0.05rem
		  }
		  66% {
		    top: -0.05rem
		  }
		  100% {
			top: 0
		  }
		}
		.tips2 {
			position: relative;
			display: inline-block;
			width: 0.16rem;
			height: 0.16rem;
			background-image: linear-gradient(90deg, #FFD700,#FFA500);
			border-radius: 0.06rem;
			margin-left: 0.10rem;
			animation: fly2 1.5s linear infinite;
		}
		@keyframes fly2 {
		  0% {
			top: 0
		  }
		  34% {
		    top: 0.05rem
		  }
		  66% {
		    top: -0.05rem
		  }
		  100% {
			top: 0
		  }
		}
		.tips3 {
			position: relative;
			display: inline-block;
			width: 0.16rem;
			height: 0.16rem;
			background-image: linear-gradient(90deg, #FF6347,#FF0000);
			border-radius: 0.06rem;
			margin-left: 0.10rem;
			animation: fly3 1.5s linear infinite;
		}
		@keyframes fly3 {
		  0% {
			top: 0
		  }
		  34% {
		    top: 0.05rem
		  }
		  66% {
		    top: -0.05rem
		  }
		  100% {
			top: 0
		  }
		}
		.to_tips {
			font-size: 0.24rem;
			color: #999;
			margin-bottom: 0.2rem;
		}
		.time_cell {
			font-size: 0.22rem;
			color: #999;
			margin-bottom: 0.2rem;
		}
		
		.hot_cell {
			width: 100%;
			display: flex;
			align-items: center;
			flex-direction: row;
			flex-wrap: nowrap;
			white-space: nowrap;
			padding: 0 0 0.24rem 0;
			overflow-x: scroll;
		}
		
		.hot_item {
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 0.04rem 0.16rem;
			font-size: 0.28rem;
			color: #666;
			border: 0.01rem solid #dedede;
			margin-left: 0.12rem;
			border-radius: 0.5rem;
			background-color: #fff;
		}
		.hot_item:nth-last-child(1) {
			margin-right: 0.12rem;
		}
		
	.hots_title {
	    color: #333;
	    font-weight: bold;
	}
	.hots_item {
	    display: flex;
	    align-items: center;
	    justify-content: space-between;
	    color: #333;
	    margin-top: 0.16rem;
	    max-width: 5.50rem;
	}
	
	.hots_wrap {
		max-width: 100%;
		padding: 0.24rem;
		background-color: #fff;
		border-radius: 0.12rem;
	}
	.hots_text {
		flex: 1;
		/* max-width: 570rpx; */
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.more_icon {
		width: 0.32rem;
		height: 0.32rem;
	}
	
	.hot_cell::-webkit-scrollbar {
	  display: none;
	}
</style>